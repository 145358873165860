import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../contexts/GlobalContext";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import AreaSettings from "./Area/AreaSettings";
import ParkingLot from "./Area/ParkingLot";
import ParkingSpace from "./Area/ParkingSpace";
import Paper from "@material-ui/core/Paper";
import { Divider, Grid } from "@material-ui/core";


const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(201,244,253) 0%, rgb(8,173,206) 50%, rgb(6,37,137) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(201,244,253) 0%, rgb(8,173,206) 50%, rgb(6,37,137) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(201,244,253) 0%, rgb(8,173,206) 50%, rgb(6,37,137) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(201,244,253) 0%, rgb(8,173,206) 50%, rgb(6,37,137) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AddBoxIcon />,
    2: <LocalParkingIcon />,
    3: <CheckBoxOutlineBlankIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["區域設定", "停車路段設定", "停車格設定"];
}

export default function Area() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedArea, setSelectedArea] = React.useState(null);
  const [selectedParkingLot, setSelectedParkingLot] = React.useState(null);
  const { t, showSnackbar } = useContext(GlobalContext);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSelectArea = (area) => {
    setSelectedArea(area);
    console.log(area.name);
  };

  const handleSelectParkingLot = (parkingLot) => {
    setSelectedParkingLot(parkingLot);
    console.log(parkingLot.name);
  };

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                  color="secondary"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={activeStep === steps.length - 1}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <Divider />
    
      <Paper style={{ margin: "20px" }}>
        {activeStep === 0 ? (
          <AreaSettings OnAreaSelected={handleSelectArea} />
        ) : activeStep === 1 ? (
          <ParkingLot
            area={selectedArea}
            OnParkingLotSelected={handleSelectParkingLot}
          />
        ) : activeStep === 2 ? (
          <ParkingSpace parkingLot={selectedParkingLot} />
        ) : null}
      </Paper>
    </div>
  );
}
