import React, { useState, createContext, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

import i18n from "../i18n";

const GlobalContext = createContext();

function GlobalProvider({ children, ...rest }) {
  // useEffect(() => {
  //   async function fetchConfig() {
  //     try {
  //       const response = await fetch('/config.json');
  //       if (response.ok) {
  //         const configData = await response.json();
  //         setApiHost(configData.apiHost);
  //         console.log(configData);
  //       } else {
  //         console.error('Failed to fetch config file');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching config:', error);
  //     }
  //   }

  //   fetchConfig();
  // }, []);

  const [locale, setLocale] = useState(
    localStorage.getItem("locale") || "zh-TW"
  );
  const [snackBar, setSnackBar] = useState({
    isOpen: false,
    severity: "info",
    message: "",
  });
  // const [ApiHost , setApiHost] = useState("192.168.1.220")

  const changeLocale = (locale) => {
    setLocale(locale);
    localStorage.setItem("locale", locale);
  };

  const showSnackbar = ({ severity = "info", message = "" }) => {
    setSnackBar({
      isOpen: true,
      severity,
      message,
    });
  };

  const value = {
    showSnackbar,
    locale,
    t: i18n(locale),
    changeLocale,
    // ApiHost
  };

  return (
    <GlobalContext.Provider value={value} {...rest}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackBar.isOpen}
        autoHideDuration={3000}
        onClose={() =>
          setSnackBar({
            ...snackBar,
            isOpen: false,
          })
        }
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() =>
            setSnackBar({
              ...snackBar,
              isOpen: false,
            })
          }
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalProvider };
