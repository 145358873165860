
import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/devicefile";

export const getDeviceFileTypes = () => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/filetypes`);
};

export const getDeviceFiles = (fileTypes) => {
  const fileTypesString = fileTypes.join(",");
  const url = `${ROOT_URL}${BASE_URL}?latestByFileTypes=${fileTypesString}`;
  console.log(url);

  return apiProvider.get(url);
};

export const getDeviceFilesByType = (fileType) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}?param[fileType]=${fileType}`);
};

export const uploadOTA = (file, data) => {
  console.log(data);

  const formData = new FormData();
  formData.append("data", JSON.stringify(data));
  formData.append("file", file);

  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, formData)
      .then((response) => {
        console.log("上傳成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("上傳失败", error);
        reject(error);
      });
  });
};

export const deleteDeviceFile = (deviceFile) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${deviceFile}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};
