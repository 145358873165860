import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";


import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


import {
  getAllParkingSpaces,
} from "../../utils/ParkingSpaceApi.js";
import {
  getParkingTickets,
  getParkingTicketHistoriesById,
  getParkingTicketSnapshotList,
} from "../../utils/ParkingTicketApi.js";
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getAllParkingLots } from "../../utils/ParkingLotApi.js";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ROOT_URL } from "../../config/api.js";
import Pagination from '@material-ui/lab/Pagination';
import ReactImageMagnify from 'react-image-magnify';
import {signalRConnection} from '../../utils/signalrService.js'
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({ 
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
   
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(5),
    height: "90%",
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  
}));

const theme = createTheme({
  typography: {
    fontSize: 16, // 設置文字大小
  },

 
});

const images = [ "./testimage/1.jpg", "./testimage/2.jpg", "./testimage/3.jpg", "./testimage/4.jpg"] 
const successImage ="./success-icon.png";

const ParkingRevise = () => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  // const { token } = useContext(AuthContext); // 取得Token
  const [parkingSpaces, setParkingSpaces] = React.useState([]);
  const [parkingTickets, setParkingTicket] = React.useState([]);
  const [selectedParkingSpaceId, setSelectedParkingSpace] = React.useState("");
  const [selectedTicketData, setSelectedTicketData] = React.useState("");
  const [openDlg, setOpenDlg] = React.useState(false);
  const [parkingLots, setParkingLots] = React.useState([]);
  const [imageItems, setImageItems] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [page, setPage] = React.useState(1); // 初始頁碼為12
  const [total, setTotal] = React.useState(0);
  const rowsPerPage = 10;
  const [parkingTicketEventData, setTicketEventData] = React.useState([]);
  const [currentEventIndex, setCurrentEventIndex] = React.useState(0);
  const [newPlateNumber, setPlateNumber] = React.useState("");
  const [successAnimationVisible, setSuccessAnimationVisible] = React.useState(false);
  useEffect(() => {
    // 在組件加載的時候得到資料
   
    GetEventData();
    
  }, []);


  const GetEventData = async()=>{
    setOpenBackdrop(true);
    const parkSpaces = await RefreshParkingSpace();
    const parkLots = await RefreshParkingLots();
    const ticketEvent = await GetParkingTicketEventData();
   
    const eventDatas =  ticketEvent? (await Promise.all(
      ticketEvent.map(async (ticket) => {
        const space = await GetParkingSpace(
          parkSpaces,
          ticket.parkingTicket.parkingSpaceId
        );
        const Lot = space
          ? await GetParkingLot(parkLots, space.parkingLotId)
          : null;

        const imageList = await Promise.all(
            ticket.parkingTicketSnapshots.map(async (imageInfo, index) => {
              return {
                img: `${ROOT_URL}/api/v1/parkingTicket/${ticket.parkingTicket.parkingTicketId}/snapshot/${imageInfo.filename}`,
                reason: imageInfo.reason,
                plate: imageInfo.plate,
              };
            })
          );  
        return {
          ticketEvent: ticket,
          parkingSpace: space,
          parkingLot: Lot,
          images : imageList
        };
      })
    )):null;

    setTicketEventData(eventDatas);
    console.log(eventDatas);
    setOpenBackdrop(false);
  }

  const GetParkingTicketEventData=async()=>{
    
    try {
      const resp = await signalRConnection.invoke('GetUnhandledEvent');
      console.log(resp);
      setTotal(resp.length);
      return resp;
    } catch (err) {
      console.error("query fail", err);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
      return null;
    }
  }

  const RefreshParkingSpace = async () => {
    try {
      const response = await getAllParkingSpaces();
      const spaces = response.data.data;
      setParkingSpaces(spaces);
      console.log(spaces);
      return spaces;
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const RefreshParkingLots = async () => {
    try {
      const response = await getAllParkingLots();
      const parkingLots = response.data.data;
      setParkingLots(parkingLots);
      return parkingLots;
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const handleClick = async () => {
    GetParkingTicket();
  };

  const GetParkingTicket = async (newPage = 1) => {
    setOpenBackdrop(true);
    try {
      const parkSpaces = await RefreshParkingSpace();
      const parkLots = await RefreshParkingLots();
      const response = await getParkingTickets(selectedParkingSpaceId, rowsPerPage*(newPage-1), rowsPerPage);
      const reports = response.data.data;
      setTotal(response.data.total);
      const ticketWithSpaceAndLot = await Promise.all(
        reports.map(async (report) => {
          const space = await GetParkingSpace(
            parkSpaces,
            report.parkingSpaceId
          );
          const Lot = space
            ? await GetParkingLot(parkLots, space.parkingLotId)
            : null;
          return {
            ticket: report,
            parkingSpace: space,
            parkingLot: Lot,
          };
        })
      );

      setParkingTicket(ticketWithSpaceAndLot);
      console.log(ticketWithSpaceAndLot);
      setOpenBackdrop(false);
    } catch (error) {
      setParkingTicket([]);
      setOpenBackdrop(false);
    }
  };

  const GetParkingSpace = async (packSpaces, parkingSpaceId) => {
    try {
      const parkSpace = packSpaces.find(
        (space) => space.parkingSpaceId === parkingSpaceId
      );
     
      return parkSpace || null;
    } catch (error) {
      return null;
    }
  };

  const GetParkingLot = async (parkLots, parkingLotId) => {
    try {
      const parkLot = parkLots.find((lot) => lot.parkingLotId === parkingLotId);
      return parkLot || null;
    } catch (error) {
      return null;
    }
  };

  
  const handleCloseDlg = () => {
    setOpenDlg(false);
    GetEventData();
  };

 



  const ISO8601toLocalTime = (iso8601Str) => {
    const iso8601Date = new Date(iso8601Str);

    function formatLocalDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const formattedLocalDateTime = formatLocalDateTime(iso8601Date);
    return formattedLocalDateTime;
  };


  const handleChangePage = (newPage) => {
    setPage(newPage);
    GetParkingTicket(newPage);
  };

  const handleManualClick = ()=>{
    if(parkingTicketEventData && parkingTicketEventData.length > 0)
      {
        setCurrentEventIndex(0);
        setPlateNumber(parkingTicketEventData[0].ticketEvent.parkingTicket.plateNumber)
      }
    setOpenDlg(true);
  }

  const handlePrev=()=>{
    if(parkingTicketEventData && parkingTicketEventData.length > 0)
    {
      if(currentEventIndex >0)
      {
        setPlateNumber(parkingTicketEventData[currentEventIndex-1].ticketEvent.parkingTicket.plateNumber)
        setCurrentEventIndex(currentEventIndex-1);
         
      }
    }
  }

  const handleNext=()=>{
    if(parkingTicketEventData && parkingTicketEventData.length > 0)
    {
      if(currentEventIndex < parkingTicketEventData.length-1)
       {
        setPlateNumber(parkingTicketEventData[currentEventIndex+1].ticketEvent.parkingTicket.plateNumber);

        setCurrentEventIndex(currentEventIndex+1);
   
       } 
    }
  }

  const handleFailRecognize = ()=>{
    if(parkingTicketEventData && parkingTicketEventData[currentEventIndex]
        &&parkingTicketEventData[currentEventIndex].ticketEvent)
    {
      signalRConnection.invoke('UpdateParkingTicketEventStatus', 
            parkingTicketEventData[currentEventIndex].ticketEvent.parkingTicketEvent.parkingTicketEventId, 
            0, newPlateNumber)
        .then((resp) => {
            console.log('handleFailRecognize:', resp);
            if(resp===true)
            {
              showSnackbar({
                message: "傳送成功",
                severity: "info",
              });

              setPlateNumber("");  //輸入框清空
              setSuccessAnimationVisible(true);
              //列表中剔除已處理的資料
              var newData = parkingTicketEventData.filter
              (x=>x.ticketEvent.parkingTicketEvent.parkingTicketEventId != parkingTicketEventData[currentEventIndex].ticketEvent.parkingTicketEvent.parkingTicketEventId);
              setTicketEventData(newData);

      
              //
              setTimeout(() => {
                setSuccessAnimationVisible(false);
                //將順序改回最前面
              setCurrentEventIndex(0);
              }, 1000); 
            }
        })
        .catch((err) => {
          console.log('handleFailRecognize:', err);
        });
    }
  }

  const handleManualRecognize = () =>{

    var chineseRegex = /[\u4e00-\u9fa5]/;
  

    if(newPlateNumber.length < 6  || newPlateNumber.length > 8  || chineseRegex.test(newPlateNumber))
    {
      showSnackbar({
        message: "錯誤車牌格式",
        severity: "error",
      });
      return;
    }


    if(parkingTicketEventData && parkingTicketEventData[currentEventIndex]
      &&parkingTicketEventData[currentEventIndex].ticketEvent)
  {
    signalRConnection.invoke('UpdateParkingTicketEventStatus', 
          parkingTicketEventData[currentEventIndex].ticketEvent.parkingTicketEvent.parkingTicketEventId, 
          1, newPlateNumber)
      .then((resp) => {
          console.log('handleFailRecognize:', resp);
          if(resp===true)
            {
              showSnackbar({
                message: "傳送成功",
                severity: "info",
              });

              setPlateNumber("");  //輸入框清空
              setSuccessAnimationVisible(true);
              //列表中剔除已處理的資料
              var newData = parkingTicketEventData.filter
              (x=>x.ticketEvent.parkingTicketEvent.parkingTicketEventId != parkingTicketEventData[currentEventIndex].ticketEvent.parkingTicketEvent.parkingTicketEventId);
              setTicketEventData(newData);

      
              //
              setTimeout(() => {
                setSuccessAnimationVisible(false);
                //將順序改回最前面
              setCurrentEventIndex(0);
              }, 1000); 
            }


      })
      .catch((err) => {
        console.log('handleFailRecognize:', err);
      });
  }
  }


  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        handlePrev();
        break;
      case 'ArrowDown':
        handleNext();
        break;
      case 'Enter':
        handleManualRecognize();
       break;
      case 'Escape':
        handleFailRecognize();
        break;
      default:
        // 其他按键的处理
        break;
    }
  };



    
  const RenderSelect = (
    <div>
      <Grid container space={3}>
        <Grid item xs={9}>
          <label htmlFor="grouped-select">選擇停車路段</label>
            <Select
              defaultValue=""
              d="grouped-select"
              onChange={(e) => setSelectedParkingSpace(e.target.value)}
              style={{ width: "200px", marginLeft: "10px" }}
            >
              {parkingLots
                ? parkingLots.map((lot, index) => (
                    <MenuItem value={lot.parkingLotId}>
                      {lot.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              className={classes.button}
              style={{
                backgroundColor: defaulttheme.buttonColor.blue,
                color: "white",
                marginLeft:"20px"
              }}
              onClick={handleClick}  

              
            >
              Get
            </Button>
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          <Button
                variant="contained"
                className={classes.button}
                style={{
                  backgroundColor: defaulttheme.buttonColor.blue,
                  color: "white",
                }}
                onClick={handleManualClick}
              >
                人工車牌作業
              </Button>
        </Grid>
        
      </Grid>
   
  </div>
  );

  const RenderDialog = (
    <Dialog
      maxWidth="lg"
      onClose={handleCloseDlg}
      aria-labelledby="simple-dialog-title"
      open={openDlg}
      fullWidth
      onKeyDown={handleKeyDown}
    >
      <DialogTitle id="simple-dialog-title" style={{backgroundColor:defaulttheme.blue.delta}}>
        <Grid container spacing={3}>
          <Grid item xs={10}  container style={{display: 'flex', alignItems: 'center', justifyContent:"center"}}>
            <label style={{color:"white"}}>
              人工車牌作業
            </label>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
              <IconButton style={{color:"red"}} aria-label="close picture" component="span"
                onClick={handleCloseDlg}
              >
                <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{width:'100%', height:'70%',  overflow: 'hidden' }}>
       
      <div
        style={{ display: "grid", gridTemplateRows: "2fr 8fr 1fr", height: "60%" }}
        >
          <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
             {
                  parkingTicketEventData &&
                        parkingTicketEventData[currentEventIndex]?
                  ( <div><h1>{parkingTicketEventData[currentEventIndex].parkingLot.name}</h1></div>) : "N/A"
             }
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
                  parkingTicketEventData &&
                        parkingTicketEventData[currentEventIndex]?
                  ( <div><h1>{parkingTicketEventData[currentEventIndex].parkingSpace.name}</h1></div>) : "N/A"
             }
             
            </Paper>
          </Grid>
        </Grid>
          <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
              <div style={{ height: '70%', display: 'flex', flexDirection: 'column', }}>
              {
                          parkingTicketEventData &&
                                parkingTicketEventData[currentEventIndex] &&
                                parkingTicketEventData[currentEventIndex].images ?
                              (                           
                                <Paper style={{width:'500px', height:'300px' ,marginBottom:"5px", overflow:'hidden'}}>
                                 
                                  <ReactImageMagnify
                                    {...{
                                      smallImage: {
                                        alt: parkingTicketEventData[currentEventIndex]?.images?.[0]?.img || null,
                                        src: parkingTicketEventData[currentEventIndex]?.images?.[0]?.img || null,
                                        isFluidWidth: true
                                      
                                      },
                                      largeImage: {
                                        src:  parkingTicketEventData[currentEventIndex]?.images?.[0]?.img || null,
                                        width: 1920,
                                        height: 1080,
                                      },
                                      enlargedImagePosition: 'over',
                                      isActivatedOnTouch: true,
                                    
                                    }}
                                    style ={{height:"90%"}}
                                  />
                                </Paper>            
                              ): 
                              (
                                <span>No Image</span>
                              )
               }
              </div>
              <div style={{ height: '30%' , width:"500px" ,overflowX: 'auto', whiteSpace: 'nowrap', }}
                justifyContent= "flex-start"
              >
                {/* <ImageList className={classes.imageList}  cols={5} gap={10} style={{ width:'auto',height: '100%',  flexWrap: 'nowrap', overflowX: 'auto'}}>  */}
                  {
                      parkingTicketEventData &&
                      parkingTicketEventData[currentEventIndex] &&
                      parkingTicketEventData[currentEventIndex].images ?
                      (
                          parkingTicketEventData[currentEventIndex].images.map((item) => (
                          // <ImageListItem ImageListItem key={item.img}  >
                              <img src={item.img} alt={item.plate} style={{width:"160px", height: 'auto', marginRight: '10px'}}/>
                                                        // </ImageListItem>
                        
                          ))

                      ):
                      (
                        <span>no images</span>
                      )
                  }     
              </div>                                           
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: theme.spacing(1),
                          textAlign: "center",                                         
                          marginBottom: theme.spacing(5),
                          height: "90%",
            
                          }}>
            {
                    parkingTicketEventData &&
                          parkingTicketEventData[currentEventIndex]?
                    (  <Typography component="p" variant="h2"  style={{ fontWeight: 'bold' }}>
                        {parkingTicketEventData[currentEventIndex].ticketEvent?
                          ISO8601toLocalTime(parkingTicketEventData[currentEventIndex].ticketEvent.parkingTicketEvent.createAt)
                          :"N/A"}
                          
                      </Typography>) : "N/A"
            }
              <div style={{marginTop:"20px"}}>
                <TextField
                  id="inputPlate"
                  autoFocus
                  variant="filled"
                  fullWidth="true"
                  size ='medium'
                  value={newPlateNumber}
                  onChange={(e) => setPlateNumber(e.target.value)}
                  style={{ 
                    width: '80%',
                    margin: '0 auto',
                    display:'block',
                    textAlign:'center'
                    }} // 調整大小和居中樣式
                    InputProps={{
                      style: {
                        fontSize: '56px', // 设置所需的字体大小
                        textAlign: 'center',
                         fontWeight: 'bold',
                         backgroundColor:"blue",
                         color:"white"
                      },
                    }}  
                />   

              </div>
              
                <div style={{marginTop:"20px"}}>
                <Button
                  variant="contained"
                  style={{margin:'20px', color:"white", backgroundColor:"red", padding:"20px"}}
                  onClick={handleFailRecognize}
                  >
                    無法辨識
                </Button>             
                <Button
                variant="contained"
                style={{margin:'20px', color:"white", backgroundColor:"green", padding:"20px"}}
                onClick={handleManualRecognize}
                >
                    人工修正
                </Button>         
                </div> 
                          
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={3} container alignItems="center" justifyContent="flex-start">
            <Button
                variant="contained"
               onClick={handlePrev}
               color = "primary"
              >PREV</Button>
        </Grid>
        <Grid item xs={3} container justifyContent="center" alignItems="center">
          
        </Grid>
        <Grid item xs={3} container justifyContent="center" alignItems="center">
           
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end" alignItems="center">      
          <Button
              variant="contained"
              onClick={handleNext}
              color = "primary"
              >NEXT</Button>
        
        </Grid>
      </Grid>

        </div>
        {successAnimationVisible && (
        <div className="success-animation">
          Success!
        </div>
         )}
      </DialogContent>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {RenderSelect}

      <div style={{marginTop:"20px", height: '50px',  display: 'flex', alignItems: 'center'}}>
        <label style={{ fontSize:"24px",margin: "20px" , fontWeight:"bold"}}>停車格開單資料</label>
      </div>
      
      <TableContainer component={Paper} style={{ margin: "0px, 10px,10px, 10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              {rowHeader.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  style={{ color: "white", fontSize: 16, fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              parkingTicketEventData?(parkingTicketEventData.slice((page-1)*rowsPerPage, (page+1-1)*rowsPerPage).map((eventData, index) =>(
              <TableRow
              key={eventData.ticketEvent?eventData.ticketEvent.parkingTicketEvent.parkingTicketEventId:index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{
                backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd",
              }}
              >
                <TableCell component="th" scope="row">
                  {eventData.parkingLot?eventData.parkingLot.name:"N/A"}
                </TableCell>
                <TableCell component="th" scope="row">
                 {eventData.parkingSpace?eventData.parkingSpace.name:"N/A"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {eventData.ticketEvent?ISO8601toLocalTime(eventData.ticketEvent.parkingTicketEvent.createAt):"N/A"}
                </TableCell>
                <TableCell component="th" scope="row">
                {eventData.images && eventData.images.length > 0 ? (
                    <img
                      src={eventData.images[0].img}
                      alt="Event Image"
                      style={{ width: '100px', height: '100px' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </TableCell>
            </TableRow>
              ))):null
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination 
          count={ total>0 ? Math.ceil(total / rowsPerPage) : 0} 
          page={page} 
          onChange={(event, newPage) => handleChangePage(newPage)}
           />
      </div>
      
      {RenderDialog}
  
  
    </ThemeProvider>
  );
};

function GetIsoTime(datetime) {
  const currentLocalDate = datetime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [month, day, year] = currentLocalDate.split("/");
  const formattedDate = `${year}-${month}-${day}`;

  const currentLocalTime = datetime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 使用24小时制
  });

  // 将日期和时间组合为ISO格式
  const currentLocalDateTime = `${formattedDate}T${currentLocalTime}`;
  return currentLocalDateTime;
}

const rowHeader = [
  "停車路段",
  "車格名稱",
  "停車時間",
  "駛入照片",
];

export default ParkingRevise;
