import React from "react";
import { Switch, BrowserRouter, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import routes from "./routes";
import Login from "../Views/Login";
import Layout from "../components/layout/Layout";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/login">
          <Login />
        </PublicRoute>
        <Layout>
          {routes.map((route) => {
            // 檢查路由的path是否有值
            console.log(route.path);
            if (route.path) {
              return (
                <PrivateRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                >
                  <route.component />
                </PrivateRoute>
              );
            }

            // 如果路由的path為空，則不渲染route.component
            return null;
          })}
          {/* 渲染子路由，不依賴主路由的path */}
          {routes.map(
            (route) =>
              route.subroutes &&
              route.subroutes.map((subroute) => (
                <PrivateRoute
                  key={subroute.path}
                  path={subroute.path}
                  exact={subroute.exact}
                >
                  <subroute.component />
                </PrivateRoute>
              ))
          )}
          <Redirect to="/" />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
};
export default AppRouter;
