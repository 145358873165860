import React, { useEffect,createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import {
  token,
  keep,
  tokenIsValid,
  setToken,
  setKeep,
} from "../utils/tokenHandler";

import {  createSignalRConnection , disconnectSignalRConnection} from '../utils/signalrService.js';

const AuthContext = createContext();

function AuthProvider(props) {
  const [tokenState, setTokenState] = useState(token);
  const [keepState, setKeepState] = useState(keep);
  // const [decodeToken, setDecodeToken] = useState(localStorage.getItem('decodeToken'));



  const login = async (jwtToken, keep) => {
    console.log("login");
    setToken(jwtToken);
    setKeep(keep);
    setTokenState(jwtToken);
    setKeepState(keep);

    console.log("Connect signalr");
    createSignalRConnection();

    // const decode = jwtDecode(jwtToken);
    // setDecodeToken(decode);

    // localStorage.setItem('keep', keep ? 1 : 0)

    // if (keep) {
    //   localStorage.setItem('token', jwtToken)
    //   localStorage.setItem('decodeToken', decode);
    // }
  };

  const logout = () => {
    setToken(null);
    setTokenState(null);
    setKeep(null);
    setKeepState(false);

    console.log("Disconnect SignalR");
    disconnectSignalRConnection();
    // localStorage.clear()
  };

  const value = {
    tokenState,
    login,
    logout,
    keepState,
    setKeepState,
    tokenIsValid,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthContext, AuthProvider , useAuth };
