import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";

import AddBoxIcon from "@material-ui/icons/AddBox";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import {
  addParkingPile,
  getParkingPiles,
  getParkingSpaceMapping,
  updateParkingPile,
  deleteParkingPile,
  getParkingMeterMapping,
} from "../../utils/ParkingPileApi";
import {
  addParkingPileAttribute,
  deleteParkingPileAttribute,
  updateParkingPileAttribute,
} from "../../utils/ParkingPileApi";
import { getParkingLotById } from "../../utils/ParkingLotApi";
import { getLocationById } from "../../utils/LocationApi";

import DescriptionIcon from "@material-ui/icons/Description";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DeleteConfirmationDialog from "../component/DeleteConfirmationDialog";
import Popover from "@material-ui/core/Popover";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  button: {
    marginRight: theme.spacing(1),
    margin: "10px",
    borderRadius: "10px",
    color: "white",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff",
  },
}));

const ParkingPile = ({ OnParkPileSelected }) => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  // const { token } = React.useContext(AuthContext); // 取得Token
  const [parkingPileData, setParkingPileData] = React.useState(null);
  const [selectedPile, setSelectedPile] = React.useState(null);
  const [openAddDlg, setOpenAddDlg] = React.useState(false);
  const [inputPileName, setInputPileName] = React.useState("");
  const [enablePile, setEnablePile] = React.useState(true);
  const [openEditDlg, setOpenEditDlg] = React.useState(false);
  const [openAddAttr, setOpenAddAttrDlg] = React.useState(false);
  const [attrName, setAttrName] = React.useState("");
  const [attrContent, setAttrContent] = React.useState("");
  const [attrEnable, setAttrEnable] = React.useState(true);
  const [selectedAttr, setSelectedAttr] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isConfirmDialogOpen, setConfirmDlgOpen] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [deleteFunction, setDeleteFunction] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [PopoverContent, setPopoverContent] = React.useState("");
  const [openPopover, setOpenPopover] = React.useState(false);
  const [PopoverId, setPopOverId] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [page, setPage] = React.useState(1); // 初始頁碼為1
  const [total, setTotal] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    // 在組件加載的時候得到資料
    RefreshParkingPile();
  }, []);

  const handlePileRowClick = (row) => {
    setSelectedPile(row);
    OnParkPileSelected(row.pileData);
    setInputPileName(row.pileData.name);
    setEnablePile(row.pileData.isEnabled);
  };

  const handleOpenAddPile = () => {
    setOpenAddDlg(true);
  };

  const handleCloseAddPile = () => {
    setOpenAddDlg(false);
  };

  const handleSavePile = () => {
    addParkingPile({
      name: inputPileName,
      isEnabled: enablePile,
    })
      .then((response) => {
        RefreshParkingPile();
      })
      .catch((error) => {
        console.error("query fail", error);
        showSnackbar({
          message: "新增失敗",
          severity: "error",
        });
      });

    setInputPileName("");
    setEnablePile(true);
    setOpenAddDlg(false);
  };

  const handleOpenEditPile = () => {
    setOpenEditDlg(true);
  };

  const handleCloseEditPile = () => {
    setOpenEditDlg(false);
  };

  const handleSaveEditPile = () => {
    updateParkingPile(selectedPile.pileData.parkPileId, {
      name: inputPileName,
      isEnabled: enablePile,
    })
      .then((response) => {
        RefreshParkingPile();
      })
      .catch((error) => {
        console.error("Update fail", error);
        showSnackbar({
          message: "修改失敗",
          severity: "error",
        });
      });

    setInputPileName("");
    setEnablePile(true);
    setOpenEditDlg(false);
  };

  const handleDeletePile = (attr) => {
    deleteParkingPile(selectedPile.pileData.parkPileId)
      .then((response) => {
        RefreshParkingPile();
      })
      .catch((error) => {
        console.error("Delete fail", error);
        showSnackbar({
          message: "刪除失敗",
          severity: "error",
        });
      });
  };

  const handleOpenAddAttr = () => {
    setSelectedAttr(null);
    setOpenAddAttrDlg(true);
  };

  const handleCloseAddAttr = () => {
    setOpenAddAttrDlg(false);
  };

  const handleSaveAttr = async () => {
    try {
      const response = await addParkingPileAttribute({
        parkPileId: selectedPile.pileData.parkPileId,
        name: attrName,
        content: attrContent,
        isEnabled: attrEnable,
      });
      if (response.isSuccess) {
        RefreshParkingPile(page);
      }
    } catch (error) {
      console.error("Add fail", error);
      showSnackbar({
        message: "新增失敗",
        severity: "error",
      });
    }
    handleCloseAddAttr();
    setSelectedAttr(null);
    setAttrName("");
    setAttrContent("");
    setAttrEnable(true);
  };

  const handleEditAttr = async () => {
    try {
      const response = await updateParkingPileAttribute(
        selectedAttr.parkPileAttributeId,
        {
          parkPileId: selectedAttr.parkPileId,
          name: attrName,
          content: attrContent,
          isEnabled: attrEnable,
        }
      );
      if (response.isSuccess) RefreshParkingPile();
    } catch (error) {
      console.error("update fail", error);
      showSnackbar({
        message: "更新失敗",
        severity: "error",
      });
    }
    handleCloseAddAttr();
    setSelectedAttr(null);
    setAttrName("");
    setAttrContent("");
    setAttrEnable(true);
  };

  const handleDeletAttr = async (attr) => {
    if (attr === null) return;

    try {
      const response = await deleteParkingPileAttribute(
        attr.parkPileAttributeId
      );
      if (response.isSuccess) RefreshParkingPile(page);
    } catch (error) {
      console.error("Delete fail", error);
      showSnackbar({
        message: "刪除失敗",
        severity: "error",
      });
    }
  };

  const RefreshParkingPile = async (newPage =1) => {
    setOpenBackdrop(true);
    try {
      const response = await getParkingPiles(rowsPerPage*(newPage-1), rowsPerPage);
      const parkingPileData = response.data.data;
      setTotal(response.data.total);
      const parkingPileWithParkingspace = await Promise.all(
        parkingPileData.map(async (data) => {
          const spaceData = await GetMappingSpace(data.parkPileId);
          const meterData = await GetMappingMeter(data.parkPileId);
          return {
            pileData: data,
            spaceData: spaceData,
            meterData: meterData,
          };
        })
      );

      setParkingPileData(parkingPileWithParkingspace);
      setOpenBackdrop(false);
      return parkingPileWithParkingspace;
    } catch (error) {
      console.error("query fail", error);
      setOpenBackdrop(false);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
      return null;
    }
  };

  const GetMappingSpace = async (pileId) => {
    try {
      const response = await getParkingSpaceMapping(pileId);
      const mappingSpace = response.data.data;
      return mappingSpace;
    } catch (error) {
      console.error("query fail", error);
      return null;
    }
  };

  const GetMappingMeter = async (pileId) => {
    try {
      const response = await getParkingMeterMapping(pileId);
      const mappingMeter = response.data.data;
      return mappingMeter;
    } catch (error) {
      console.error("query fail", error);
      return null;
    }
  };

  const handleOpenConfirmDialog = (item, onDeleteFunction) => {
    console.log("open delete dlg");
    if (item === null) return;
    setConfirmDlgOpen(true);
    setItemToDelete(item);
    setDeleteFunction(() => onDeleteFunction);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDlgOpen(false);
    setItemToDelete("");
    setDeleteFunction(null);
  };

  const handleClickAttrChip = (attr) => {
    setSelectedAttr(attr);
    setAttrName(attr.name);
    setAttrContent(attr.content);
    setAttrEnable(attr.isEnabled);
    setOpenAddAttrDlg(true);
  };

  const handleClickSpace = async (event, space) => {
    event.persist();

    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);

    const parkingLot = await GetParkingLot(space.parkingLotId);
    if (parkingLot === null) {
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
      return;
    }

    const location = await GetLocation(parkingLot.locationId);
    if (location === null) {
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
      return;
    }

    const locationName = location.name;
    const parkingLotName = parkingLot.name;
    const content = `${locationName}-${parkingLotName}-${space.name}`;
    setPopoverContent(content);
    setPopOverId(space.parkingSpaceId);

    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  const GetParkingLot = async (parkingLotId) => {
    try {
      const response = await getParkingLotById(parkingLotId);
      const parkingLot = response.data.data;
      return parkingLot;
    } catch (error) {
      console.error("query fail", error);
      return null;
    }
  };

  const GetLocation = async (locationId) => {
    try {
      const response = await getLocationById(locationId);
      const location = response.data.data;
      return location;
    } catch (error) {
      console.error("query fail", error);
      return null;
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    RefreshParkingPile(newPage);
  };

  const handleSearch = async() =>{
    const parkPileWithSpace = await RefreshParkingPile();
    const parkPileWithSpace2 = parkPileWithSpace.filter(pile => pile.pileData.name.includes(searchQuery));
    setTotal(parkPileWithSpace2.length);
    setParkingPileData(parkPileWithSpace2);
  }

  const RenderParkingPile = (
    <Paper>
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              <TableCell
                key="name"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                名稱
              </TableCell>
              <TableCell
                key="mapping"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                關聯車格
              </TableCell>
            
              <TableCell
                key="meter"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                停車表
              </TableCell>
              <TableCell
                key="attr"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                屬性
              </TableCell>
              <TableCell
                key="action"
                style={{ color: "white", fontSize: 16, width: "5%" }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parkingPileData
              ? parkingPileData.map((data, index) =>
                  data.pileData ? (
                    <>
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          height: "10px",
                        }}
                        onClick={() => handlePileRowClick(data)}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedPile &&
                            selectedPile.pileData.parkPileId ===
                              data.pileData.parkPileId
                              ? "#bad3e8"
                              : "white",
                        }}
                      >
                        <TableCell
                          key="name"
                          align="left"
                          style={{ fontSize: 16, width: "20%" }}
                        >
                          {data.pileData.isEnabled
                            ? data.pileData.name
                            : data.pileData.name + "(停用)"}
                        </TableCell>
                        <TableCell
                          key="enable"
                          align="left"
                          style={{ fontSize: 16, width: "20%" }}
                        >
                          <List>
                            {data.spaceData
                              ? data.spaceData.map((space) => (
                                  <ListItem key={space.parkingSpaceId}>
                                    <Button
                                      aria-describedby={space.parkingSpaceId}
                                      onClick={(event) =>
                                        handleClickSpace(event, space)
                                      }
                                      color="primary"
                                    >
                                      {space.name}
                                    </Button>
                                  </ListItem>
                                ))
                              : null}
                          </List>
                        </TableCell>
                        
                        <TableCell key="meter">
                          {data.meterData
                            ? data.meterData.map((meter) => (
                                <Chip
                                  key={meter.manufacturerDeviceId}
                                  icon={<DescriptionIcon />}
                                  label={meter.manufacturerDeviceId}
                                  color={
                                    meter.isEnabled ? "secondary" : "default"
                                  }
                                  style={{ margin: "3px" }}
                                />
                              ))
                            : null}
                        </TableCell>
                        <TableCell
                          key="attr"
                          style={{ fontSize: 16, width: "20%" }}
                        >
                          {data.pileData.attributes
                            ? data.pileData.attributes.map((attr) => (
                                <Chip
                                  key={attr.parkPileAttributeId}
                                  icon={<DescriptionIcon />}
                                  label={attr.name}
                                  color={attr.isEnabled ? "primary" : "default"}
                                  clickable
                                  onClick={() => handleClickAttrChip(attr)}
                                  onDelete={() =>
                                    handleOpenConfirmDialog(
                                      attr,
                                      handleDeletAttr
                                    )
                                  }
                                  style={{ margin: "3px" }}
                                />
                              ))
                            : null}
                        </TableCell>
                        <TableCell
                          key="action"
                          align="right"
                          style={{ fontSize: 16, width: "20%" }}
                        >
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={handleOpenAddAttr}
                          >
                            <AddIcon />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : null
                )
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination 
          count={ total>0 ? Math.ceil(total / rowsPerPage) : 0} 
          page={page} 
          onChange={(event, newPage) => handleChangePage(newPage)}
           />
      </div>
    </Paper>
  );

  const RenderSearch = (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center" style={{ margin: "20px" }}>
            <TextField
              label="關鍵字"
              variant="outlined"
              style={{ borderRadius: "20px", width: "300px" }} //圓角與自動伸縮
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              style={{ margin: "10px", borderRadius: "10px" }}
              onClick={handleSearch}
            >
              搜索
            </Button>
          </Box>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <Box display="flex" alignItems="center" style={{ margin: "20px" }}>
            <Button
              variant="contained"
              startIcon={<AddBoxIcon />}
              className={classes.button}
              style={{ backgroundColor: defaulttheme.buttonColor.green }}
              onClick={handleOpenAddPile}
            >
              Add
            </Button>
            <Button
              variant="contained"
              startIcon={<UpdateIcon />}
              className={classes.button}
              style={{ backgroundColor: defaulttheme.buttonColor.grey }}
              onClick={handleOpenEditPile}
            >
              Edit
            </Button>

            <Button
              variant="contained"
              startIcon={<DeleteIcon />}
              className={classes.button}
              style={{ backgroundColor: defaulttheme.buttonColor.red }}
              onClick={() =>
                handleOpenConfirmDialog(
                  selectedPile && selectedPile.pileData
                    ? selectedPile.pileData
                    : null,
                  handleDeletePile
                )
              }
            >
              delete
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const RenderAddParkingPile = (
    <Dialog
      open={openAddDlg}
      onClose={handleCloseAddPile}
      aria-labelledby="addform-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle id="addform-dialog-title">新增停車柱</DialogTitle>
      <DialogContent>
        <form className={classes.formControl}>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="名稱"
              onChange={(e) => setInputPileName(e.target.value)}
            />
          </div>

          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>停用</Grid>
              <Grid item>
                <Switch
                  checked={enablePile}
                  onChange={(e) => setEnablePile(e.target.checked)}
                  name="isEnabled"
                  color="primary"
                />
              </Grid>
              <Grid item>啟用</Grid>
            </Grid>
          </Typography>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSavePile}>
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseAddPile}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderEditParkingPile = (
    <Dialog
      open={openEditDlg}
      onClose={handleCloseEditPile}
      aria-labelledby="addform-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle id="addform-dialog-title">修改車柱</DialogTitle>
      <DialogContent>
        <form className={classes.formControl}>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="名稱"
              onChange={(e) => setInputPileName(e.target.value)}
              value={inputPileName}
            />
          </div>

          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>停用</Grid>
              <Grid item>
                <Switch
                  checked={enablePile}
                  onChange={(e) => setEnablePile(e.target.checked)}
                  name="isEnabled"
                  color="primary"
                />
              </Grid>
              <Grid item>啟用</Grid>
            </Grid>
          </Typography>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveEditPile}
        >
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseEditPile}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderAttrDlg = (
    <Dialog
      open={openAddAttr}
      onClose={handleCloseAddAttr}
      aria-labelledby="addattrform-dialog-title"
    >
      <DialogTitle id="addattrform-dialog-title">新增屬性</DialogTitle>
      <DialogContent>
        <h3> {selectedPile ? selectedPile.pileData.name : null}</h3>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="名稱"
          fullWidth={true}
          onChange={(e) => setAttrName(e.target.value)}
          value={attrName}
        />
        <TextField
          margin="dense"
          id="content"
          label="內容"
          fullWidth={true}
          onChange={(e) => setAttrContent(e.target.value)}
          value={attrContent}
        />

        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>停用</Grid>
            <Grid item>
              <Switch
                checked={attrEnable}
                onChange={(e) => setAttrEnable(e.target.checked)}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>啟用</Grid>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={selectedAttr ? handleEditAttr : handleSaveAttr}
        >
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseAddAttr}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={classes.root}>
      <h3>停車柱管理</h3>
      {RenderSearch}
      {RenderParkingPile}
      {RenderAddParkingPile}
      {RenderEditParkingPile}
      {RenderAttrDlg}
      <DeleteConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onDelete={deleteFunction}
        item={itemToDelete}
      />
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Popover
        id={PopoverId}
        open={openPopover}
        anchorEl={anchorEl || undefined}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>{PopoverContent}</Typography>
      </Popover>
    </div>
  );
};

export default ParkingPile;
