import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { getAllParkingSpaces } from "../../utils/ParkingSpaceApi";
import {
  getParkingTickets,
  getParkingTicketHistoriesById,
  getParkingTicketSnapshotList,
} from "../../utils/ParkingTicketApi";
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import ImageIcon from "@material-ui/icons/Image";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getAllParkingLots } from "../../utils/ParkingLotApi";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ROOT_URL } from "../../config/api.js";
import Pagination from "@material-ui/lab/Pagination";
import ReactImageMagnify from "react-image-magnify";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const theme = createTheme({
  typography: {
    fontSize: 16, // 設置文字大小
  },
});

const TicketReport = () => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  // const { token } = useContext(AuthContext); // 取得Token
  const [parkingSpaces, setParkingSpaces] = React.useState([]);
  const [parkingTickets, setParkingTicket] = React.useState([]);
  const [selectedParkingSpaceId, setSelectedParkingSpace] = React.useState("");
  const [selectedTicketData, setSelectedTicketData] = React.useState("");
  const [openDlg, setOpenDlg] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [parkingLots, setParkingLots] = React.useState([]);
  const [ticketHistories, setTicketHistories] = React.useState([]);
  const [imageItems, setImageItems] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [page, setPage] = React.useState(1); // 初始頁碼為1
  const [total, setTotal] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    // 在組件加載的時候得到資料
    RefreshParkingSpace();
    RefreshParkingLots();
    GetParkingTicket();
  }, []);

  const RefreshParkingSpace = async () => {
    try {
      let allSpaces = [];
      let offset = 0;
      let limit = 10;
      // 循环直到没有更多数据为止
      while (true) {
        const response = await getAllParkingSpaces(offset, limit);
        const spaces = response.data.data;
        allSpaces = [...allSpaces, ...spaces];

        if (spaces.length < limit) {
          break;
        }
        offset += limit;
      }

      // const response = await getAllParkingSpaces();
      // const spaces = response.data.data;
      setParkingSpaces(allSpaces);
      return allSpaces;
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const RefreshParkingLots = async () => {
    try {
      let allParkingLots = [];
      let offset = 0;
      let limit = 10;

      while (true) {
        const response = await getAllParkingLots(offset, limit);
        const lots = response.data.data;
        allParkingLots = [...allParkingLots, ...lots];

        if (lots.length < limit) {
          break;
        }
        offset += limit;
      }
      setParkingLots(allParkingLots);
      return allParkingLots;
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const handleClick = async () => {
    GetParkingTicket();
  };

  const GetParkingTicket = async (newPage = 1) => {
    setOpenBackdrop(true);
    try {
      const parkSpaces = await RefreshParkingSpace();
      const parkLots = await RefreshParkingLots();
      const response = await getParkingTickets(
        selectedParkingSpaceId,
        rowsPerPage * (newPage - 1),
        rowsPerPage
      );
      const reports = response.data.data;
      setTotal(response.data.total);
      const ticketWithSpaceAndLot = await Promise.all(
        reports.map(async (report) => {
          const space = await GetParkingSpace(
            parkSpaces,
            report.parkingSpaceId
          );
          const Lot = space
            ? await GetParkingLot(parkLots, space.parkingLotId)
            : null;

          return {
            ticket: report,
            parkingSpace: space,
            parkingLot: Lot,
          };
        })
      );

      setParkingTicket(ticketWithSpaceAndLot);
      console.log(ticketWithSpaceAndLot);
      setOpenBackdrop(false);
    } catch (error) {
      setParkingTicket([]);
      setOpenBackdrop(false);
    }
  };

  const GetParkingSpace = async (parkSpaces, parkingSpaceId) => {
    try {
      const parkSpace = parkSpaces.find(
        (space) => space.parkingSpaceId === parkingSpaceId
      );
      console.log(parkSpace);
      return parkSpace || null;
    } catch (error) {
      return null;
    }
  };

  const GetParkingLot = async (parkLots, parkingLotId) => {
    try {
      const parkLot = parkLots.find((lot) => lot.parkingLotId === parkingLotId);
      return parkLot || null;
    } catch (error) {
      return null;
    }
  };

  const handleRawData = async (ticket) => {
    setSelectedTicketData(ticket);

    setOpenBackdrop(true);
    await getParkingTicketHistoriesById(ticket.parkingTicketId)
      .then((response) => {
        const histories = response.data.data;
        if (histories)
          histories.sort((a, b) => new Date(b.createAt) - new Date(a.createAt));

        setTicketHistories(histories);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        console.error("query fail", error);
        setTicketHistories(null);
        setOpenBackdrop(false);
        showSnackbar({
          message: "取得資料失敗",
          severity: "error",
        });
      });

    setOpenDlg(true);
  };

  const handleCloseDlg = () => {
    setOpenDlg(false);
  };

  const handleClickPreview = async (ticket) => {
    setSelectedTicketData(ticket);

    setOpenBackdrop(true);
    const images = await GetTicketImage(ticket.parkingTicketId);
    console.log(images);
    const imageList = await Promise.all(
      images.map(async (image, index) => {
        return {
          img: `${ROOT_URL}/api/v1/parkingTicket/${ticket.parkingTicketId}/snapshot/${image.filename}`,
          author: "author",
          title: index,
          time: image.time,
          reason: image.reason,
        };
      })
    );

    setImageItems(imageList);
    console.log(imageList);
    setOpenBackdrop(false);

    setOpenPreview(true);
  };

  const GetTicketImage = async (ticketId) => {
    try {
      const response = await getParkingTicketSnapshotList(ticketId);
      return response.data.data;
    } catch (error) {
      return null;
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const ISO8601toLocalTime = (iso8601Str) => {
    const iso8601Date = new Date(iso8601Str);

    function formatLocalDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const formattedLocalDateTime = formatLocalDateTime(iso8601Date);
    return formattedLocalDateTime;
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    GetParkingTicket(newPage);
  };

  const ReanderPreviewImage = (
    <Dialog
      onClose={handleClosePreview}
      aria-labelledby="simple-dialog-title"
      open={openPreview}
      PaperProps={{
        style: {
          width: "50%",
          height: "50%",
          position: "absolute",
          top: "25%",
          left: "25%",
        },
      }}
    >
      <DialogTitle id="simple-dialog-title">Images</DialogTitle>
      <DialogContent>
        <ImageList className={classes.imageList} cols={1}>
          {imageItems
            ? imageItems.map((item) => (
                <ImageListItem key={item.img} style={{ height: "auto" }}>
                  {
                    /* <img src={item.img} alt={item.title} /> */
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: item.title,
                          src: item.img,
                          isFluidWidth: true,
                          hoverOffSetX: 0,
                          hoverOffSetY: 0,
                        },
                        largeImage: {
                          src: item.img,
                          width: 1920,
                          height: 1080,
                        },
                        enlargedImagePosition: "over",
                        isActivatedOnTouch: false,
                        isHintEnabled: true,
                      }}
                    />
                  }
                  {/* <ImageComponent image={item.img}/> */}
                  <ImageListItemBar
                    title={ISO8601ToDateTime(item.time)}
                    position="top"
                    subtitle={item.reason}
                    className={classes.titleBar}
                    style={{ pointerEvents: "none" }}
                  />
                </ImageListItem>
              ))
            : null}
        </ImageList>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClosePreview}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderSelect = (
    <div>
      <FormControl className={classes.formControl}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <label htmlFor="grouped-select">選擇停車格</label>
            <Select
              defaultValue=""
              d="grouped-select"
              onChange={(e) => setSelectedParkingSpace(e.target.value)}
              style={{ width: "200px", marginLeft: "10px" }}
            >
              {parkingSpaces
                ? parkingSpaces.map((space, index) => (
                    <MenuItem value={space.parkingSpaceId}>
                      {space.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              className={classes.button}
              style={{
                backgroundColor: defaulttheme.buttonColor.blue,
                color: "white",
              }}
              onClick={handleClick}
            >
              Get
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );

  const RenderDialog = (
    <Dialog
      maxWidth="xl"
      onClose={handleCloseDlg}
      aria-labelledby="simple-dialog-title"
      open={openDlg}
    >
      <DialogTitle id="simple-dialog-title">Raw Data</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: defaulttheme.blue.delta }}>
                <TableCell align="left">Time</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Content</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketHistories.map((history) => (
                <TableRow>
                  <TableCell align="left">
                    {ISO8601toLocalTime(history.createAt)}
                  </TableCell>
                  <TableCell align="left">{history.type}</TableCell>
                  <TableCell align="left">{history.content}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCloseDlg}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {RenderSelect}

      <h3 style={{ margin: "20px" }}>停車格開單資料</h3>

      <TableContainer component={Paper} style={{ margin: "10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              {rowHeader.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  style={{ color: "white", fontSize: 16, fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {parkingTickets.map((ticketData, index) => (
              <TableRow
                key={ticketData.ticket.parkingTicketId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd",
                }}
              >
                <TableCell component="th" scope="row">
                  {ticketData.parkingSpace ? ticketData.parkingSpace.name : ""}
                </TableCell>
                <TableCell align="left">
                  {ticketData.parkingLot ? ticketData.parkingLot.name : ""}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.plateNumber}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.carInAt
                    ? ISO8601toLocalTime(ticketData.ticket.carInAt)
                    : null}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.carOutAt
                    ? ISO8601toLocalTime(ticketData.ticket.carOutAt)
                    : null}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.parkingTicketStatus}
                </TableCell>
                <TableCell align="left">
                  <IconButton
                    color="primary"
                    aria-label="rawdata"
                    component="span"
                    className={classes.button}
                    style={{
                      backgroundColor: defaulttheme.buttonColor.blue,
                      color: "white",
                    }}
                    onClick={() => handleRawData(ticketData.ticket)}
                  >
                    <MessageIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="upicture"
                    component="span"
                    className={classes.button}
                    style={{
                      backgroundColor: defaulttheme.buttonColor.blue,
                      color: "white",
                    }}
                    onClick={() => handleClickPreview(ticketData.ticket)}
                  >
                    <ImageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Pagination
          count={total > 0 ? Math.ceil(total / rowsPerPage) : 0}
          page={page}
          onChange={(event, newPage) => handleChangePage(newPage)}
        />
      </div>

      {RenderDialog}
      {ReanderPreviewImage}
    </ThemeProvider>
  );
};

function GetIsoTime(datetime) {
  const currentLocalDate = datetime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [month, day, year] = currentLocalDate.split("/");
  const formattedDate = `${year}-${month}-${day}`;

  const currentLocalTime = datetime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 使用24小时制
  });

  // 将日期和时间组合为ISO格式
  const currentLocalDateTime = `${formattedDate}T${currentLocalTime}`;
  return currentLocalDateTime;
}

function ISO8601ToDateTime(isoDateString) {
  const date = new Date(isoDateString);

  // 確保Date物件有效，並格式化日期和時間
  if (!isNaN(date.getTime())) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else {
    return "Invalid Date";
  }
}

const rowHeader = [
  "車格名稱",
  "停車路段",
  "車牌號碼",
  "入車時間",
  "出車時間",
  "開單狀態",
  "",
];

export default TicketReport;
