import React, { useContext, useState } from "react";

import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import routes from "../../routers/routes";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Typography } from "@material-ui/core";
import {version} from "../../config/api";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.blue.dark,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: theme.blue.dark,
  },
  node: {
    color: "#ffffff",
    // transition: 'background-color 0.2s ease',
    "&:hover": {
      backgroundColor: "rgba(82, 149, 255, 0.4)",
    },
    "& > div > span": {
      marginLeft: 10,
      fontSize: 16,
    },
    "& > *:first-child": {
      height: 32,
      width: 32,
      margin: "auto",
    },
  },
  nodeActive: {
    backgroundColor: theme.blue.delta,
  },

  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
}));

const Siderbar = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const [subitemOpen, setSubitemOpen] = useState(
    Array(routes.length).fill(false)
  );
  const location = useLocation();
  const { t } = useContext(GlobalContext);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick2 = (index) => {
    // 切換特定項目的展開狀態
    const newSubitemOpen = [...subitemOpen];
    newSubitemOpen[index] = !newSubitemOpen[index];
    setSubitemOpen(newSubitemOpen);
  };

  return (
    <>
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        {open && (
          <img
            style={{ width: 150, margin: "auto" }}
            src={process.env.PUBLIC_URL + "/delta-logo.png"}
            alt="logo"
          />
        )}
        <IconButton edge="end" onClick={handleClick}>
          {open ? (
            <ArrowBackIosIcon color="primary" />
          ) : (
            <ArrowForwardIosIcon color="primary" />
          )}
        </IconButton>
      </div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {routes.map((route, index) => {
          const isPathValid = Boolean(route.path); // 檢查路由的path是否有值

          const elementToRender = isPathValid ? (
            <NavLink key={route.path} to={route.path}>
              <ListItem
                className={clsx(classes.node, {
                  [classes.nodeActive]: location.pathname === route.path,
                })}
              >
                <route.icon style={{ marginRight: "10px" }} />
                {open && <ListItemText primary={t(`${route.name}`)} />}
              </ListItem>
            </NavLink>
          ) : (
            <>
              <ListItem
                className={clsx(classes.node, {
                  [classes.nodeActive]: location.pathname === route.path,
                })}
                button
                onClick={() => handleClick2(index)}
              >
                <route.icon style={{ marginRight: "10px" }} />
                {open && <ListItemText primary={t(`${route.name}`)} />}
                {open && (subitemOpen[index] ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>
              {open && (
                <Collapse in={subitemOpen[index]} timeout="auto" unmountOnExit>
                  {route.subroutes
                    .filter((subroute) => subroute.parent === route.name) // 過濾符合條件的subroute
                    .map((subroute) => (
                      <NavLink key={subroute.path} to={subroute.path}>
                        <ListItem
                          className={clsx(classes.node, {
                            [classes.nodeActive]:
                              location.pathname === subroute.path,
                          })}
                          key={subroute.name}
                        >
                          <subroute.icon
                            style={{ marginRight: "10px", marginLeft: "10px" }}
                          />
                          <ListItemText primary={t(`${subroute.name}`)} />
                        </ListItem>
                      </NavLink>
                    ))}
                </Collapse>
              )}
            </>
          );

          return elementToRender;
        })}
      </List>
      <Typography variant="caption" className={classes.versionNumber} style={{color:"white", fontSize:"16px", textAlign: "center"}}>
        version：{version} 
      </Typography>
    </Drawer>
    
    </>
  );
};

// const Siderbar = ({ open, setOpen }) => {
//   const classes = useStyles();
//   const location = useLocation();
//   const { t } = useContext(GlobalContext);
//   let openItem =true;
//   const handleClick = () => {
//     openItem =!openItem;
//   };

//   return (
//     <Drawer
//       variant="permanent"
//       className={clsx(classes.drawer, {
//         [classes.drawerOpen]: open,
//         [classes.drawerClose]: !open,
//       })}
//       classes={{
//         paper: clsx({
//           [classes.drawerOpen]: open,
//           [classes.drawerClose]: !open,
//         }),
//       }}
//     >
//       <div className={classes.toolbar}>
//         {open && <img style={{ width: 150, margin: 'auto' }} src={process.env.PUBLIC_URL + '/delta-logo.png'} alt="logo" />
//         }
//         <IconButton edge="end" onClick={() => setOpen(!open)}>
//           {open ? <ArrowBackIosIcon color="primary" /> : <ArrowForwardIosIcon color="primary" />}
//         </IconButton>
//       </div>

//       {
//        <List>
//         {
//           routes.map(route =>
//             <NavLink key={route.path} to={route.path}>
//               <ListItem className={clsx(classes.node, {
//                 [classes.nodeActive]: location.pathname === route.path,
//               })} >
//                 <route.icon style={{marginRight:'10px'}}/>
//                 {open && <ListItemText primary={t(`${route.name}`)} />}

//               </ListItem>

//             </NavLink>)
//         }
//       </List> }

//     </Drawer>)
// }

export default Siderbar;
