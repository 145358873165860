import React, { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../contexts/GlobalContext.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";
import AddIcon from "@material-ui/icons/Add";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Button, DialogContent, DialogContentText, Fab } from "@material-ui/core";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getRoles, updateRole,addRole, deleteRole, addRoleAttribute, updateRoleAttribute, deleteRoleAttribute} from '../../utils/RoleApi.js'
import {getAuthorizationNames, getAuthorizationById, getAuthorizationByName}  from '../../utils/AuthorizationApi.js'
import Pagination from '@material-ui/lab/Pagination';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteConfirmationDialog from "../component/DeleteConfirmationDialog";
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff",
  },
  dialog: {
    zIndex: theme.zIndex.drawer + 1,
  },
  
  tableRow: {
    fontSize: "16px",
    fontWeight: 'bold'
  },
}));

const UserRole = () => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
   const [userRoles, setUserRoles] = React.useState([]);
   const [authorizationNames, setAuthorizationNames] = React.useState([]);
   const [authorizations, setAuthorizations] = React.useState([]);
   const [page, setPage] = React.useState(1); // 初始頁碼為1
   const [total, setTotal] = React.useState(0);
   const [openDlg, setOpenDlg] = React.useState(false);
   const [selectedAuthorIds, setSelectedAuthorIds] = React.useState([]);
   const [selectedAllAuthorNames, setSelectedAllAuthorNames] = React.useState([]);
 
   const [isEmptyName, setIsEmptyName] = React.useState(false);
   const [errorHint, setErrorHint] = React.useState("");
  const [inputName, setInputName] = React.useState("");
  const [isConfirmDialogOpen, setConfirmDlgOpen] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [deleteFunction, setDeleteFunction] = React.useState(null);
  const [currentRole, setCurrentRole] = React.useState(null);
  const [openAddAttr, setOpenAddAttr] = React.useState(false);
  const [attrName, setAttrName] =React.useState("");
  const [attrContent, setAttrContent] = React.useState("");
  const [attrEnable, setAttrEnable] = React.useState(true);
  const [selectedAttr, setSelectedAttr] = React.useState(null);  
   const rowsPerPage = 10;


  useEffect(() => {
    // 在組件加載的時候得到資料
     GetUserRoles();
     GetAllAuthorizationName();
     GetAllAuthorizations();
    
  }, []);

  useEffect(() => {
    console.log(selectedAuthorIds);
  }, [selectedAuthorIds]);


  const GetUserRoles = async(newPage = 1)=>{

    setOpenBackdrop(true);
    try
    {
      const res = await getRoles( rowsPerPage*(newPage-1), rowsPerPage);
      const roles = res.data.data;
      setTotal(res.data.total);


      const roleWithAuths = await Promise.all(
        roles.map(async (role) => {
          const authorizationDetails = await GetAuthorizationsByIds(role.rolesAuthorizationIds);

           return{
               roleInfo: role,
               authors : authorizationDetails
           };
        })
      );
  
      setUserRoles(roleWithAuths);
      console.log(roleWithAuths);
    }
    catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
    setOpenBackdrop(false);
  };

  const GetAuthorizationsByIds = async(authorizationIds) =>{

    const authorizationDetails = await Promise.all(
      authorizationIds.map((authorizationId) => (
           
          GetAuthorizationById(authorizationId)
               
          ))
        );
   
  return  authorizationDetails;
       
  }

  const GetAuthorizationById= async( id) =>{
    try
    {
      const res = await getAuthorizationById(id);
      return res.data.data;
    }
    catch(error)
    {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };


  const GetAllAuthorizationName = async () => {
    setOpenBackdrop(true);
    try
    {
      const res = await getAuthorizationNames();
      const names = res.data.data;
      setAuthorizationNames(names);
      console.log(names);
    }
    catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
    setOpenBackdrop(false);
  };


  const GetAllAuthorizations = async()=>{
    setOpenBackdrop(true);
    try
    {
      const AuthorizationDetails = await Promise.all(
        authorizationNames.map( async(name)=>{

          const authors = await getAuthorizationByName(name);
 
          return{
            authorName : name,
            details : authors.data.data
          };
        }      
      ));
      setAuthorizations(AuthorizationDetails);
      console.log(AuthorizationDetails);
    }
    catch(error)
    {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
    setOpenBackdrop(false);
  }
  


const handleChangePage = (newPage) => {
    setPage(newPage);
    GetUserRoles(newPage);
  };


const handleCloseDlg =()=>{
  setCurrentRole(null);
  setSelectedAllAuthorNames([]);
  setSelectedAuthorIds([])
  setOpenDlg(false);
}
  

const OnChangeRoleEnable = async(role, isEnabled) =>{
  try
  {
    role.isEnabled = !isEnabled;
    const res = await updateRole( role.roleId,
      {
        name: role.roleName,
        isEnabled: !isEnabled,
        attributes: role.attributes
      }
    );
    if(res.isSuccess)
    {
      showSnackbar({
        message: "更新成功",
        severity: "info",
      });
    }
    else
    {
      showSnackbar({
        message: "更新失敗",
        severity: "error",
      });
    }
  }
  catch(error)
  {
    console.log(error);
    showSnackbar({
      message: "更新失敗",
      severity: "error",
    });
  }
}



const handleAddDlgOpen = ()=>{
  GetAllAuthorizations();
  setSelectedAuthorIds([]);
  setInputName("");
  setOpenDlg(true);
}

const OnHandleChangeAuthorizationAll=(author_all, checked)=>{

     setSelectedAuthorIds(prevSelectedAuthorIds =>{
      if(checked){
        if(!prevSelectedAuthorIds.includes(author_all.authorizationId))
            return [...prevSelectedAuthorIds, author_all.authorizationId];
          else
              return prevSelectedAuthorIds;
      }
      else{
            if(prevSelectedAuthorIds.includes(author_all.authorizationId))
                return prevSelectedAuthorIds.filter(id => id !== author_all.authorizationId)
             else
              return prevSelectedAuthorIds;
      } 

     })

    const name =author_all.name;
    setSelectedAllAuthorNames( prevSelectedAllAuthorNames=>{
      if(checked)
      {
        if(!prevSelectedAllAuthorNames.includes(name)){
          return [...prevSelectedAllAuthorNames, name];
        }
        else
          return prevSelectedAllAuthorNames;
      }
      else
      {
        if(prevSelectedAllAuthorNames.includes(name)){
          return prevSelectedAllAuthorNames.filter(authorName => authorName !== name);
          }
          else
          return prevSelectedAllAuthorNames;
      }
        
    })

}

const OnHandleChangeAuthorization = (authorId, checked) =>{
    setSelectedAuthorIds( prevSelectedAuthorIds =>{
      if(checked){
        if(!prevSelectedAuthorIds.includes(authorId)){
          return [...prevSelectedAuthorIds, authorId];
        }
        else
          return prevSelectedAuthorIds;
      }
      else
        if(prevSelectedAuthorIds.includes(authorId)){
          return prevSelectedAuthorIds.filter(id => id !== authorId)
        }
  })
}

const OnHandleSaveRole= async()=>{
  if(inputName.trim() ==="")
  {
    setErrorHint("請輸入名稱");
    setIsEmptyName(true);
  }
  else
  {

    if(currentRole === null )
    {
        try
        {
          const res = await addRole({
            name: inputName,
            isEnabled : true,
            rolesAuthorizationIds: selectedAuthorIds,
            attributes: []
          });

          if(res.isSuccess)
          {
            showSnackbar({
              message: "新增成功",
              severity: "info",
            });
            GetUserRoles();
          }
        }
        catch(error)
        {
          console.log(error);
          showSnackbar({
            message: "新增失敗",
            severity: "error",
          });
        }
    }
    else
    {
      try
        {
          const res = await updateRole( currentRole.roleId, {
            name: inputName,
            isEnabled : true,
            rolesAuthorizationIds: selectedAuthorIds,
            attributes: []
          });

          if(res.isSuccess)
          {
            showSnackbar({
              message: "更新成功",
              severity: "info",
            });
            GetUserRoles();
          }
        }
        catch(error)
        {
          console.log(error);
          showSnackbar({
            message: "更新失敗",
            severity: "error",
          });
        }
    }
    setOpenDlg(false);
  }
}

const handleOpenDeleteConfirmDlg = (item, onDeleteFunction) =>{
  setConfirmDlgOpen(true);
  if (item === null) return;
  setConfirmDlgOpen(true);
  setItemToDelete(item);
  setDeleteFunction(() => onDeleteFunction);

}

const handleCloseConfirmDialog = ()=>{
    setConfirmDlgOpen(false);
    setItemToDelete("");
    setDeleteFunction(null);
}

const handleDeleteRole = async( role)=>{
  if(role === null) return

  try
  {
    const res = await deleteRole(role.roleId);
    if(res.isSuccess)
    {
      showSnackbar({
        message: "刪除成功",
        severity: "info",
      });
      GetUserRoles();
    }
    else
    {
      showSnackbar({
        message: "刪除失敗",
        severity: "error",
      });
    }
  }
  catch(error)
  {
    console.log(error);
    showSnackbar({
      message: "新增失敗",
      severity: "error",
    });
  }
}

const handleOpenEditDlg = (userRole)=>{
  GetAllAuthorizations();
  setCurrentRole(userRole.roleInfo);
  userRole.authors.forEach(element => {
    const {authorizationId } = element;
    setSelectedAuthorIds( prevSelectedAuthorIds =>{
          if(!prevSelectedAuthorIds.includes(authorizationId)){
            return [...prevSelectedAuthorIds, authorizationId];
          }
          else
            return prevSelectedAuthorIds;      
    })
  });

 
  const author_all = userRole.authors.filter(obj=>obj.scope=="All");
  author_all.forEach(author =>{
    const {name} = author;

    setSelectedAllAuthorNames( preSelectedNames =>{
      if(!preSelectedNames.includes(name))
        return [...preSelectedNames, name];
      else
        return preSelectedNames
    })
  })


  setInputName(userRole.roleInfo.roleName);
  setOpenDlg(true);
}

const getAvatarColor = (scope) => {
  const colorMap = {
    A: 'blue',   
    C: 'purple',  
    R: 'green', 
    U: 'orange',
    D: 'red', 
  };

  const defaultColor = 'gray';

  return colorMap[scope] || defaultColor;
};

const handleOpenAddAttr=(role)=>{

  setCurrentRole(role);
  setOpenAddAttr(true);
}

const handleCloseAddAttr=()=>{

  setOpenAddAttr(false);
  setCurrentRole(null);
  setSelectedAttr(null);
  setAttrName("");
  setAttrContent("");
  setAttrEnable(true);
}



const handleOpenEditAttr = (role,attr)=>{
  setCurrentRole(role);
  setSelectedAttr(attr);
  setAttrName(attr.name);
  setAttrContent(attr.content);
  setAttrEnable(attr.isEnabled);
  setOpenAddAttr(true);
}

const handleEditAttr = async()=>{

  try{
      const res = await updateRoleAttribute(selectedAttr.roleAttributeId,{
        name:attrName, 
        content: attrContent,
        roleId: currentRole.roleId,
        isEnabled: attrEnable
      });
      
      if(res.isSuccess)
      {
        showSnackbar({
          message: "更新成功",
          severity: "info",
        });
        GetUserRoles();
      }
      else
      {
        showSnackbar({
          message: "更新失敗",
          severity: "error",
        });
      }

    }
    catch(error)
    {
      console.log(error);
      showSnackbar({
        message: "更新失敗",
        severity: "error",
      });
    }

    handleCloseAddAttr();

}

const handleSaveAttr = async()=>{
  try
  {
   
    const res = await addRoleAttribute({
      name:attrName, 
      content: attrContent,
      roleId: currentRole.roleId,
      isEnabled: attrEnable
    });
     
    if(res.isSuccess)
    {
      showSnackbar({
        message: "新增成功",
        severity: "info",
      });
      GetUserRoles();
    }
    else
    {
      showSnackbar({
        message: "新增失敗",
        severity: "error",
      });
    }

  }
  catch(error)
  {
    console.log(error);
    showSnackbar({
      message: "新增失敗",
      severity: "error",
    });
  }

  handleCloseAddAttr();
}

const handleDeleteAttr = async(roleAndAttr)=>{
  try
  {
 
     const res = await deleteRoleAttribute(roleAndAttr.attribute.roleAttributeId);
    
    if(res.isSuccess)
    {
      showSnackbar({
        message: "刪除成功",
        severity: "info",
      });
      GetUserRoles();
    }
    else
    {
      showSnackbar({
        message: "刪除失敗",
        severity: "error",
      });
    }

  }
  catch(error)
  {
    console.log(error);
    showSnackbar({
      message: "更新失敗",
      severity: "error",
    });
  }
} 



const RenderAttrDlg = (
  <Dialog
    open={openAddAttr}
    onClose={handleCloseAddAttr}
    aria-labelledby="addattrform-dialog-title"
  >
    <DialogTitle id="addattrform-dialog-title">{selectedAttr?"修改屬性":"新增屬性"}</DialogTitle>
    <DialogContent>
      <h3> { currentRole? currentRole.roleName : null}</h3>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="名稱"
        fullWidth={true}
        onChange={(e) => setAttrName(e.target.value)}
        value={attrName}
      />
      <TextField
        margin="dense"
        id="content"
        label="內容"
        fullWidth={true}
        onChange={(e) => setAttrContent(e.target.value)}
        value={attrContent}
      />

      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>停用</Grid>
          <Grid item>
            <Switch
              checked={attrEnable}
              onChange={(e) => setAttrEnable(e.target.checked)}
              name="isEnabled"
              color="primary"
            />
          </Grid>
          <Grid item>啟用</Grid>
        </Grid>
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={selectedAttr ? handleEditAttr : handleSaveAttr}
      >
        確定
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseAddAttr}
      >
        取消
      </Button>
    </DialogActions>
  </Dialog>
);


const RenderAddDialog=(
  <>
    <Dialog
      maxWidth="lg"
      onClose={handleCloseDlg}
      aria-labelledby="simple-dialog-title"
      open={openDlg}
      fullWidth= {true}
    >
      <DialogTitle id="simple-dialog-title">新增角色</DialogTitle>
      <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label={"角色名稱"}
            value={inputName}
            onChange={(e) => setInputName(e.target.value)}
            error = {isEmptyName}
            helperText ={isEmptyName?errorHint:""}
            InputProps={{
              style: {
                fontSize: '24px', // 设置所需的字体大小
                textAlign: 'center',
                 fontWeight: 'bold',
                
              },
            }}  

            InputLabelProps={{
              style: {
                fontSize: '24px', // 设置所需的字体大小
                textAlign: 'center',
                 fontWeight: 'bold',
                
              },
            }}

            FormHelperTextProps={{
              style: {
                fontSize: '24px', // 设置所需的字体大小
                 fontWeight: 'bold',
                
              },

            }}
            //fullWidth
          />
      
      <TableContainer component={Paper} style={{ margin: "10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
             {rowHeader_dlg.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  style={{ color: "white", fontSize: "16px" , width: `${header.width}%`}}
                >
                  {header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              authorizations?authorizations.map((author, index)=>(
                <TableRow
                key={author}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{
                backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd"}}
                >
                   <TableCell component="th" scope="row"
                    className={classes.tableRow}
                    style={{ fontWeight: 'bold' }}
                    >
                      {author.authorName}
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={2}>
                          {
                            (()=>{
                              if(author.details !== undefined)
                              {
                                const author_all = author.details.find(obj=>obj.scope=="All");
                                 
                                if(author_all !== undefined)
                                 {
                                 
                                    return(
                                          <FormControlLabel
                                          control={<Switch color="primary" />}
                                          label={
                                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            All
                                            </Typography>
                                          }
                                          labelPlacement="top"
                                          style={{marginLeft:"50px"}}                            
                                          onChange={(event)=>OnHandleChangeAuthorizationAll(author_all, event.target.checked)}
                                          checked ={selectedAuthorIds.includes(author_all.authorizationId)}
                                        />                                
                                      )                 
                                  }
                              }                                       
                            })()                   
                          }                   
                        </Grid>
                        <Grid item xs={10}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            {
                              author.details?
                                author.details.filter((detail) => detail.name === author.authorName && detail.scope !== "All")
                                .sort((a, b) => {
                                  //排序
                                  const order = ["Create", "Read", "Update", "Delete"];
                                  return order.indexOf(a.scope) - order.indexOf(b.scope);
                                })
                                .map((detail)=>{                             
                                    return(
                                          <FormControlLabel
                                    
                                          control={<Switch color="primary" />}
                                          label={
                                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                              {detail.scope}
                                            </Typography>
                                          }
                                          labelPlacement="top"
                                          style={{marginLeft:"50px"}}
                                          onChange={(event)=>OnHandleChangeAuthorization(detail.authorizationId, event.target.checked)}
                                          checked={selectedAuthorIds.includes(detail.authorizationId)}
                                          disabled = {selectedAllAuthorNames.includes(detail.name)}
                                        />
                                      )
                                  }):null
                            }
                          </FormGroup>
                      </FormControl>
                        </Grid>

                      </Grid>                   
                    </TableCell>
                </TableRow>
               
              )):null
            }
          </TableBody>
        </Table>
      </TableContainer>

          
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleCloseDlg}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={OnHandleSaveRole}>
          OK
        </Button>
       
      </DialogActions>
    </Dialog>
  </>
) ;
 

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container justifyContent="flex-end">
        <Grid item xs={6}>
          <h2 style={{ margin: "20px" }}>角色列表</h2>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ margin: "10px" }}
            onClick={()=> handleAddDlgOpen()}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      
      <TableContainer component={Paper} style={{ margin: "10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              {rowHeader.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  style={{ color: "white", fontSize: "16px" , width: `${header.width}%`}}
                >
                  {header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              userRoles? userRoles.map((userRole,index) =>(
               
                <TableRow
                  key={userRole.roleInfo.roleId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd"}}
                >

                <TableCell component="th" scope="row" className={classes.tableRow}>
                  {userRole.roleInfo?userRole.roleInfo.roleName:""}
                </TableCell>

                <TableCell component="th" scope="row" >
                {
                  userRole.authors? userRole.authors.map((auth) =>(
                    <Chip
                      key={auth.authorizationId}
                      label={auth.name}
                      color="primary"
                      avatar={
                          <Avatar style={{ backgroundColor: getAvatarColor(auth.scope.substring(0,1)) }}>
                              {auth.scope.substring(0,1)}
                          </Avatar>                  
                        }
                      style={{ margin: "3px",  }}
                    
                    ></Chip>  
                  )):null

                }
                </TableCell>

                <TableCell>
                  <Grid container>
                    <Grid item xs={8}>
                      {
                        userRole.roleInfo?userRole.roleInfo.attributes.map((attr)=>(
                          <Chip
                            label={attr.name}
                            clickable
                            color= {attr.isEnabled?"secondary":"default"}
                            onDelete={()=> 
                              handleOpenDeleteConfirmDlg({role: userRole.roleInfo, attribute: attr}, handleDeleteAttr)
                            }
                            onClick={()=>handleOpenEditAttr(userRole.roleInfo, attr) }
                            style={{margin:"3px"}}
                          >

                          </Chip>
                        )):null

                      }
                    </Grid>
                    <Grid item xs={4}>
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={()=>handleOpenAddAttr(userRole.roleInfo)}
                      >
                        <AddIcon />
                      </Fab>
                    </Grid>
                  </Grid>

                  
                </TableCell>

                <TableCell align="right">
                  <Grid container justify-content="flex-end">
                    <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="delete"
                          component="span"
                          className={classes.button}
                          style={{
                            backgroundColor: defaulttheme.buttonColor.blue,
                            color: "white",
                          }}
                           onClick={() => handleOpenEditDlg(userRole)}
                        >
                              <EditIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="history"
                          component="span"
                          className={classes.button}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                          }}
                         onClick={() => handleOpenDeleteConfirmDlg(userRole.roleInfo, handleDeleteRole)}
                        >
                          <DeleteIcon />
                        </IconButton>         
                    </Grid>
                    <Grid item>                       
                        <FormGroup>
                          <FormControlLabel
                            control={ <Switch
                              checked = {userRole.roleInfo.isEnabled} 
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              onChange={()=>OnChangeRoleEnable(userRole.roleInfo, userRole.roleInfo.isEnabled)}
                            />}
                            label="啟用"
                            labelPlacement="top"
                           
                          />                   
                    </FormGroup>
                    </Grid>

                  </Grid>                    
                </TableCell>

                </TableRow>
              )):null
            }       
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination 
          count={ total>0 ? Math.ceil(total / rowsPerPage) : 0} 
          page={page} 
          onChange={(event, newPage) => handleChangePage(newPage)}
           />
      </div>
      {RenderAddDialog}
      {RenderAttrDlg}
      <DeleteConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onDelete={deleteFunction}
        item={itemToDelete}
      />
    </>
  );
};

const rowHeader = [ { name:"角色名稱", width: 20},  {name: "角色權限",  width: 45} , {name: "角色屬性",  width: 20},{name:"操作", width:15}];
const rowHeader_dlg = [{name:"權限名稱", width: 20},  {name: "權限範圍",  width: 80}]

export default UserRole;
