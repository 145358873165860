import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Category,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CountUp from "react-countup";
import GaugeChart from "react-gauge-chart";
import GaugeComponent from "react-gauge-component";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);
ChartJS.register(ChartDataLabels);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(5),
    height: "90%",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%", // 使用100%高度
  },

  gridContainer: {
    height: "50%",
  },
  centered: {
    // 加上逗號
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  countUp: {
    // 加上逗號
    fontSize: "256px" /* 調整您想要的放大尺寸 */,
  },
}));

const data1 = {
  labels: ["停車", "未使用"],
  datasets: [
    {
      label: "總停車率",
      data: [79, 21],
      backgroundColor: ["#2d7fd7", "#5cb6f1"],
      hoverOffset: 4,
    },
  ],
};

const data2 = {
  labels: ["開單", "未開單"],
  datasets: [
    {
      label: "周轉率",
      data: [59, 41],
      backgroundColor: ["#2d7fd7", "#5cb6f1"],
      hoverOffset: 4,
    },
  ],
};

const data3 = {
  labels: ["開單", "未開單"],
  datasets: [
    {
      label: "總開單率",
      data: [68, 32],
      backgroundColor: ["#2d7fd7", "#5cb6f1"],
      hoverOffset: 4,
    },
  ],
};

const options = {
  plugins: {
    title: {
      display: true,
      text: "總停車率(%)",
      font: {
        size: 24,
      },
      color: "#000",
    },

    legend: {
      display: true,
      position: "top",
      labels: {
        font: {
          size: 14,
        },
        color: "#000",
      },
    },

    datalabels: {
      color: "white",
      formatter: (value, context) => {
        return context.chart.data.labels[context.dataIndex];
      },
      anchor: "end",
      align: "start",
      offset: -10,
      font: {
        weight: "bold",
        size: 14,
      },
    },
  },
};

const options2 = {
  plugins: {
    title: {
      display: true,
      text: "周轉率",
      font: {
        size: 24,
      },
      color: "#000",
    },

    legend: {
      display: true,
      position: "top",
      labels: {
        font: {
          size: 14,
        },
        color: "#000",
      },
    },

    datalabels: {
      color: "white",
      formatter: (value, context) => {
        return context.chart.data.labels[context.dataIndex];
      },
      anchor: "end",
      align: "start",
      offset: -10,
      font: {
        weight: "bold",
        size: 14,
      },
    },
  },
};

const options3 = {
  plugins: {
    title: {
      display: true,
      text: "總開單率(%)",
      font: {
        size: 24,
      },
      color: "#000",
    },

    legend: {
      display: true,
      position: "top",
      labels: {
        font: {
          size: 14,
        },
        color: "#000",
      },
    },

    datalabels: {
      color: "white",
      formatter: (value, context) => {
        return context.chart.data.labels[context.dataIndex];
      },
      anchor: "end",
      align: "start",
      offset: -10,
      font: {
        weight: "bold",
        size: 14,
      },
    },
  },
};

const data_line = {
  labels: [
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
  ],
  datasets: [
    {
      label: "停車次數",
      data: [65, 59, 80, 81, 56, 55, 40, 50, 66, 56],
      fill: false,
      borderColor: "#2d7fd7",
      tension: 0.1,
    },
  ],
};

const data_bar = {
  labels: [
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
  ],
  datasets: [
    {
      label: "開單次數",
      data: [65, 59, 75, 79, 51, 50, 40, 40, 60, 55],
      backgroundColor: "#2d7fd7",
      borderColor: "#2d7fd7",
      borderWidth: 1,
    },
  ],
};

function Dashboard() {
  const classes = useStyles();

  return (
    <div
      style={{ display: "grid", gridTemplateRows: "1fr 1fr", height: "100%" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Pie style={{ margin: 10 }} data={data1} options={options} />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Pie style={{ margin: 10 }} data={data3} options={options3} />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <div className={classes.centered}>
              <label
                style={{
                  color: "black",
                  fontSize: "24px",
                  margin: "20px",
                }}
              >
                周轉率
              </label>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >         
                <GaugeChart
                      id="gauge-chart3"
                      nrOfLevels={3}
                      colors={["red", "yellow", "green"]}
                      arcWidth={0.3}
                      percent={0.75}
                      textColor={"black"}
                      // hideText={true} // If you want to hide the text
                    />
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Line style={{ margin: 10 }} data={data_line} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Bar data={data_bar} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
