import React, { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import defaulttheme from "../../customTheme.js";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  getParkingLots,
  addParkingLot,
  updateParkingLot,
  deleteParkingLot,
} from "../../utils/ParkingLotApi";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Pagination from '@material-ui/lab/Pagination';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  button: {
    marginLeft: "5px",
    marginRight: "5px",
  },

  tableRow: {
    fontSize: "16px",
  },
}));

function ParkingLot({ area, OnParkingLotSelected }) {
  const classes = useStyles();
  // const { token } = useContext(AuthContext);  // 取得Token
  const [open, setOpen] = React.useState(false);
  const [open_edit, setOpenEdit] = React.useState(false);
  const [inputName, setInputName] = React.useState("");
  const [inputSection, setInputSection] = React.useState("");
  const [inputDescription, setInputDescription] = React.useState("");
  const [parkingLotData, setParkingLotData] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const { showSnackbar } = useContext(GlobalContext);
  const [selectedArea, setSelectedArea] = React.useState(null);
  const [enable, setEnable] = React.useState(true);
  const [page, setPage] = React.useState(1); // 初始頁碼為1
  const [total, setTotal] = React.useState(0);
  const rowsPerPage = 10;

  const RefreshParkingLot = useCallback((id, newPage=1) => {
    getParkingLots(id, rowsPerPage*(newPage-1), rowsPerPage)
      .then((response) => {
        const parkingLotData = response.data.data;
        setParkingLotData(parkingLotData);
        setTotal(response.data.total);
      })
      .catch((error) => {
        console.error("query fail", error);
      });
  }, []);

  useEffect(() => {
    // 在組件加載的時候得到資料
    if (area) {
      RefreshParkingLot(area.locationId);
      setSelectedArea(area);
    } else {
      showSnackbar({
        message: "請先選擇區域",
        severity: "error",
      });
    }
  }, area);

  const handleAddClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    addParkingLot({
      name: inputName,
      locationId: selectedArea.locationId,
      description: inputDescription,
      section: inputSection,
      isEnabled: enable,
    })
      .then((response) => {
        RefreshParkingLot(selectedArea.locationId);
      })
      .catch((error) => {
        console.error("query fail", error);
      });
    setInputDescription(""); // 清空輸入框
    setInputName("");
    setInputSection("");
    handleClose();
  };

  const handleEdit = () => {
    updateParkingLot({
      parkingLotId: selectedRow.parkingLotId,
      name: inputName,
      locationId: selectedArea.locationId,
      description: inputDescription,
      section: inputSection,
      isEnabled: enable,
    })
      .then((response) => {
        RefreshParkingLot(selectedArea.locationId);
      })
      .catch((error) => {
        console.error("query fail", error);
      });
    setInputDescription(""); // 清空輸入框
    setInputName("");
    setInputSection("");
    handleCloseEdit();
  };

  const handleDeleteClick = () => {
    if (!selectedRow) {
      showSnackbar({
        message: "請先選擇路段",
        severity: "error",
      });
      return;
    }

    deleteParkingLot(selectedRow.parkingLotId)
      .then((response) => {
        RefreshParkingLot(selectedArea.locationId);
      })
      .catch((error) => {
        console.error("delete fail", error);
      });
  };

  const handleEditClick = () => {
    if (!selectedRow) {
      showSnackbar({
        message: "請先選擇路段",
        severity: "error",
      });
      return;
    }
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleRowClick = (data) => {
    setSelectedRow(data);
    setEnable(data.isEnabled);
    setInputDescription(data.description);
    setInputName(data.name);
    setInputSection(data.section);
    if (OnParkingLotSelected) OnParkingLotSelected(data);
  };

  const handleChangeEnable = (isEnabled) => {
    setEnable(isEnabled);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    RefreshParkingLot(selectedArea.locationId, newPage);
  };

  const renderButton = (
    <>
      <Button
        variant="contained"
        startIcon={<AddBoxIcon />}
        className={classes.button}
        style={{
          backgroundColor: defaulttheme.buttonColor.green,
          color: "white",
        }}
        onClick={handleAddClick}
      >
        Add
      </Button>

      <Button
        variant="contained"
        startIcon={<UpdateIcon />}
        className={classes.button}
        style={{
          backgroundColor: defaulttheme.buttonColor.grey,
          color: "white",
        }}
        onClick={handleEditClick}
      >
        Edit
      </Button>

      <Button
        variant="contained"
        startIcon={<DeleteIcon />}
        className={classes.button}
        style={{
          backgroundColor: defaulttheme.buttonColor.red,
          color: "white",
        }}
        onClick={handleDeleteClick}
      >
        delete
      </Button>
    </>
  );

  return (
    <>
      <Grid container spacing={3}>
        <h2 style={{ margin: "20px" }}>
          {selectedArea ? selectedArea.name : "尚未選擇區域"}
        </h2>
        <Grid item xs={12}>
          <Paper variant="outlined" style={{ margin: "20px" }}>
            <Grid container spacing={3} style={{ marginTop: "20px" }}>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "10px" }}>停車路段</h3>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                {renderButton}
              </Grid>
              <TableContainer
                component={Paper}
                style={{ margin: "10px" }}
                fullwidth
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: defaulttheme.blue.delta }}
                    >
                      <TableCell
                        key="area"
                        align="left"
                        style={{
                          color: "white",
                          fontSize: 20,
                          width: "25%",
                          borderBottom: "1px solid white",
                        }}
                      >
                        區域
                      </TableCell>
                      <TableCell
                        key="parkinglot"
                        align="left"
                        style={{
                          color: "white",
                          fontSize: 20,
                          width: "25%",
                          borderBottom: "1px solid white",
                        }}
                      >
                        路段
                      </TableCell>
                      <TableCell
                        key="description"
                        align="left"
                        style={{
                          color: "white",
                          fontSize: 20,
                          width: "50%",
                          borderBottom: "1px solid white",
                        }}
                      >
                        敘述
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parkingLotData.map((data) => (
                      <TableRow
                        key={data.parkingLotId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => handleRowClick(data)}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedRow &&
                            selectedRow.parkingLotId === data.parkingLotId
                              ? "#e0f0fb"
                              : "white",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableRow}
                        >
                          {data.isEnabled ? data.name : data.name + " (停用)"}
                        </TableCell>
                        <TableCell align="left" className={classes.tableRow}>
                          {data.section}
                        </TableCell>
                        <TableCell align="left" className={classes.tableRow}>
                          {data.description}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>           
            </Grid>
          
          </Paper>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Pagination 
                  count={ total>0 ? Math.ceil(total / rowsPerPage) : 0} 
                  page={page} 
                  onChange={(event, newPage) => handleChangePage(newPage)}
                  />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="addform-dialog-title"
      >
        <DialogTitle id="addform-dialog-title">新增停車路段</DialogTitle>
        <DialogContent>
          <h3> {selectedArea ? selectedArea.name : null}</h3>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="名稱"
            fullWidth
            onChange={(e) => setInputName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="section"
            label="區段"
            fullWidth
            onChange={(e) => setInputSection(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="敘述"
            fullWidth
            onChange={(e) => setInputDescription(e.target.value)}
          />
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>停用</Grid>
              <Grid item>
                <Switch
                  checked={enable}
                  onChange={(e) => handleChangeEnable(e.target.checked)}
                  name="isEnabled"
                  color="primary"
                />
              </Grid>
              <Grid item>啟用</Grid>
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSave}>
            確定
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            取消
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open_edit}
        onClose={handleCloseEdit}
        aria-labelledby="addform-dialog-title"
      >
        <DialogTitle id="editform-dialog-title">修改停車路段</DialogTitle>
        <DialogContent>
          <h3> {selectedArea ? selectedArea.name : null}</h3>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="名稱"
            fullWidth
            onChange={(e) => setInputName(e.target.value)}
            value={inputName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="section"
            label="區段"
            fullWidth
            onChange={(e) => setInputSection(e.target.value)}
            value={inputSection}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="敘述"
            fullWidth
            onChange={(e) => setInputDescription(e.target.value)}
            value={inputDescription}
          />
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>停用</Grid>
              <Grid item>
                <Switch
                  checked={enable}
                  onChange={(e) => handleChangeEnable(e.target.checked)}
                  name="isEnabled"
                  color="primary"
                />
              </Grid>
              <Grid item>啟用</Grid>
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleEdit}>
            確定
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseEdit}
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ParkingLot;
