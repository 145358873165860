import axios from "axios";

import { token, tokenIsValid } from "./tokenHandler";

const client = axios.create();

client.interceptors.request.use(
  (config) => {
    // debugger;
    if (!tokenIsValid()) {
      // invalid token back to Login
      throw new Error("Invalid token");
    }

    // append token to header
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    console.log("axios.interceptors", error);
    return Promise.reject(error);
  }
);

export default client;
 