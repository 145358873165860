import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/parkpile";

export const getParkingPiles = (skip=0, amount=10) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}?withrelation=true&skip=${skip}&amount=${amount}`);
};

export const getParkingPileById = (parkingPileId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingPileId}`);
};

export const getParkingSpaceMapping = (parkPileId) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/mapping/${parkPileId}/parkingspace`
  );
};

export const getParkingMeterMapping = (parkPileId) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/mapping/${parkPileId}/parkingmeter`
  );
};

export const addParkingPile = (parkingPileData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, parkingPileData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateParkingPile = (parkingPileId, parkingPileData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(`${ROOT_URL}${BASE_URL}/${parkingPileId}`, parkingPileData)
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteParkingPile = (parkingPileId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${parkingPileId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};

export const deleteParkingPileAttribute = (parkingPileAttrId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/attribute/${parkingPileAttrId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};

export const addParkingPileAttribute = (data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/attribute`, data)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateParkingPileAttribute = (attrId, data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(`${ROOT_URL}${BASE_URL}/attribute/${attrId}`, data)
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};
